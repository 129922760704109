<template>
  <div class="container-grid grid-3">
    <div class="container-grid__item grid-last-name">
      <div>
        <input v-model="model.lastname" :class="{invalid: errors.has('lastname')}" v-validate.immediate="{ required: true }" name="lastname" type="text" class="form-control" id="form-last-name" placeholder="Фамилия" required/>
      </div>
    </div>
    <div class="container-grid__item grid-first-name">
      <div>
        <input v-model="model.firstname" :class="{invalid: errors.has('firstname')}" v-validate.immediate="{ required: true }" name="firstname" type="text" class="form-control" id="form-first-name" placeholder="Имя" required/>
      </div>
    </div>
    <div class="container-grid__item grid-patronic-name">
      <div>
        <input v-model="model.middlename" :class="{invalid: errors.has('middlename')}" name="middlename" type="text" class="form-control" id="form-patronic-name" placeholder="Отчество"/>
      </div>
    </div>
    <div class="container-grid__item grid-email">
      <div>
        <input v-model="model.email" :class="{invalid: errors.has('email')}" v-validate.immediate="'required|email'" type="email" name="email" class="form-control" id="form-register-email" placeholder="E-mail" required/>
      </div>
    </div>
    <div class="container-grid__item grid-city">
      <div :class="{invalid: errors.has('city_name_ru')}">
        <autocomplete :class="{invalid: errors.has('city_name_ru')}" id="city" name="city_name_ru" v-model="model.city" placeholder="Город" />
      </div>
    </div>
    <div class="container-grid__item grid-phone">
      <div v-if="false">
        <input type="text" class="form-control" id="form-phone" placeholder="+7 (999) 123-45-67" required/>
        <div class="invalid-feedback">Введите телефон</div>
      </div>
    </div>
    <div class="container-grid__item grid-pass">
      <div>
        <input key="password" v-model="model.password" :class="{'invalid': errors.has('password')}" name="password" v-validate.immediate="'required|min:6'" type="password" ref="password" class="form-control" id="form-pass-1" placeholder="Введите пароль" required />
      </div>
    </div>
    <div class="container-grid__item grid-pass-2">
      <div>
        <input key="password_confirmation" v-model="model.passwordConfirmation" :class="{'invalid': errors.has('password_confirmation')}" name="password_confirmation" v-validate.immediate="'required|min:6|confirmed:password'" type="password" class="form-control" id="form-pass-2" placeholder="Повторите пароль" required/>
      </div>
    </div>
    <div class="container-grid__item grid-descr">
      <div class="form-description">Пароль должен содержать латинские буквы и цифры, а длина пароля — <strong class="content_700">не менее 6 знаков</strong>.
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from "@/components/Autocomplete.vue";
import vmMixin from "@/mixins/vmMixin";

export default {
  name: "RegistrationFormUser",
  components: {Autocomplete},
  mixins: [vmMixin]
}
</script>

<style scoped>

</style>