<template>
  <form rel="restore-pass" class="modal-form needs-validation active" :class="{'validated': errors.has('emailRestore')}" @submit.prevent="submit" novalidate>
    <div class="modal-form__body">
      <div class="form-item">
        <label for="form-email-restore">Вы получите письмо с инструкциями для смены пароля.</label>
        <input  v-model="email" :class="{'invalid': errors.has('emailRestore')}" v-validate.immediate="{ required: true }" name="emailRestore" type="email" class="form-control" id="form-email-restore" placeholder="Введите e-mail"
                required/>
        <div class="invalid-feedback" :class="{'d-block': errors.has('emailRestore')}">Введите e-mail</div>
      </div>

      <div class="notice notice_red restore-notice">
        <div class="content_subtitle content_red content_700 mb-1">Нет аккаунта с таким логином</div>
        <div class="content_18">Возможно, он удалён или его никогда не было.</div>
      </div>
    </div>
    <div class="modal-form__footer">
      <div class="m-0 w-100 modal-footer-restore modal-footer-animation">
        <div class="row align-items-center">
          <div class="col">
            <div class="content_18"><a rel="log-in" class="nav-form content_red content_underline modal-restore" href="javascript:void(0)">Вернуться к
              авторизации</a></div>
          </div>
          <div class="col-auto">
            <button type="submit" class="link-target"><span>отправить</span></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import ApiService from "../../services/api.service";
import {eventBus} from "@/main";

export default {
  name: "RestorePassForm",
  data() {
    return {
      email: null,
      send: false
    }
  },
  methods: {
    async submit() {
      let result = await this.$validator.validateAll()
      if(result) {
        await ApiService.restore(this.email)
        this.send = true
        eventBus.$emit('show:letter', {email: this.email, title: 'Информация для востановления отправлена на указанный адрес'})
      }
    }
  }
}
</script>

<style scoped>

</style>