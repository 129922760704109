<template>
  <form ref="registration" rel="registration" @submit.prevent="registration" class="needs-validation modal-form modal-form-registration" :class="{'was-validated active': registerError}" novalidate>
    <div class="modal-form__body">
      <registration-form-sms-checkbox />
      <registration-form-user v-if="type === 'user'" v-model="register"/>
      <registration-form-reg-team v-if="type === 'regteam'" v-model="register" />
      <registration-form-company v-if="type === 'company'" v-model="register" />
      <registration-form-condition v-model="aggree" />
      <form-error v-if="registerError" :error="registerError" />
    </div>
    <registration-form-button :disabled="!aggree" />
    <registration-form-sms />
  </form>
</template>

<script>
import ApiService from "../../../services/api.service";
import FormError from "@/components/FormError.vue";
import {eventBus} from "@/main";
import RegistrationFormCondition from "@/components/register/RegistrationFormCondition.vue";
import RegistrationFormSmsCheckbox from "@/components/register/RegistrationFormSmsCheckbox.vue";
import RegistrationFormButton from "@/components/register/RegistrationFormButton.vue";
import RegistrationFormSms from "@/components/register/RegistrationFormSms.vue";
import RegistrationFormUser from "@/components/register/RegistrationFormUser.vue";
import RegistrationFormRegTeam from "@/components/register/RegistrationFormRegTeam.vue";
import regTypeMixin from "@/mixins/regTypeMixin";
import RegistrationFormCompany from "@/components/register/RegistrationFormCompany.vue";
import {mapGetters} from "vuex";

export default {
  name: "RegistrationForm",
  components: {
    RegistrationFormCompany,
    RegistrationFormRegTeam,
    RegistrationFormUser,
    RegistrationFormSms,
    RegistrationFormButton, RegistrationFormSmsCheckbox, RegistrationFormCondition, FormError},
  data() {
    return {
      register: {
        city: null,
        password: null,
        passwordConfirmation: null,
        region: null
      },
      aggree: false,
      registerError: false
    }
  },
  mixins: [regTypeMixin],
  methods: {
    async registration() {

      if(!this.aggree) {
        this.registerError = new Error('Подтвердите согласие с условиями пользовательского соглашения и политикой обработки персональных данных!');
        return;
      }

      this.registerError = false

      let result = await this.$validator.validateAll()

      if (!result) {
        this.registerError = new Error('Проверьте правильность ввода полей формы')
        return;
      }

      let resp = {}
      try {
        resp = (await ApiService.register( {
          ...this.register,
          ...{plain_password: this.register.passwordConfirmation},
          ...{is_region_team: this.type === 'regteam'},
          ...{company: this.type === 'company'},
          ...{mspForsageOrderRequest: this.mspForsageOrderRequest},
          ...{region: this.register.region ? this.register.region.id : null}
        }))
        this.loading = false
      } catch (err) {
        this.loading = false
        this.registerError = err
      }

      if(resp.status && resp.status !== 200) {

        if(resp.data.errors && resp.data.errors.errors) {
          resp.data = resp.data.errors.errors
        }

        this.registerError = new Error(resp.data)
        this.loading = false
        return;
      }

      eventBus.$emit('show:letter', {email: this.register.email, title: 'Для завершения регистрации Вам необходимо пройти по ссылке, которая направлена на адрес'})

      return false;

    }
  },
  computed: {
    ...mapGetters(['mspForsageOrderRequest'])
  }
}
</script>

<style>

</style>