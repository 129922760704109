<template>
  <div rel="letter" class="modal-letter-success modal-form">
    <div class="modal-letter-success__notice p-24 mb-24">
      <div class="d-flex">
        <div class="flex-grow-0">
          <img src="/pic/icon-letter-blue.svg" class="mr-24" />
        </div>
        <div class="flex-grow-1">
          <div class="content_18">{{ title }}</div>
          <div class="content_subtitle content_700">{{ email }}</div>
        </div>
      </div>
    </div>

    <div class="notice notice_red mb-24">
      <div class="content_18">
        <div class="content_red content_700">Письмо не пришло? На всякий случай проверьте:</div>
        <ul class="list list_dotted">
          <li>не попало ли письмо в папку «Спам»;</li>
          <li>нет ли ошибки в Вашем электронном адресе.</li>
        </ul>
      </div>
    </div>

    <div class="content_18 mb-5">
      Если у вас возникли вопросы, связанные с регистрацией или доступом на сайт, напишите нам на <a href="mailto:support@exportedu.ru" class="content_red content_underline">support@exportedu.ru</a>
    </div>

    <div class="d-flex justify-content-center">
      <div class="btn-socials">
        <a class="container-shadow btn-social btn-social__vk" href="https://vk.com/exportedu" target="_blank"></a>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "SuccessLetter",
  props: {
    email: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'Для завершения регистрации Вам необходимо пройти по ссылке, которая направлена на адрес'
    }
  }
}
</script>

<style scoped>

</style>