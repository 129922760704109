<template>
  <div :class="{'container-grid grid-2': !mspForsageOrderRequest}">
    <div class="container-grid__item grid-email">
      <div>
        <input key="email" v-model="model.email" :class="{invalid: errors.has('email')}" v-validate.immediate="'required|email'" type="email" name="email" class="form-control" id="form-register-email" placeholder="E-mail" required/>
      </div>
    </div>
    <div class="container-grid__item grid-region" v-if="!order">
      <autocomplete :class="{invalid: errors.has('city_name_ru')}" id="city" name="city_name_ru" v-model="model.city" placeholder="Город" />
    </div>
    <div class="container-grid__item grid-pass">
      <div>
        <input key="password" v-model="model.password" :class="{'invalid': errors.has('password')}" name="password" v-validate.immediate="'required|min:6'" type="password" ref="password" class="form-control" id="form-pass-1" placeholder="Введите пароль" required />
      </div>
    </div>
    <div class="container-grid__item grid-pass-2">
      <div>
        <input key="password_confirmation" v-model="model.passwordConfirmation" :class="{'invalid': errors.has('password_confirmation')}" name="password_confirmation" v-validate.immediate="'required|min:6|confirmed:password'" type="password" class="form-control" id="form-pass-2" placeholder="Повторите пароль" required/>
      </div>
    </div>
    <div class="container-grid__item grid-descr">
      <div class="form-description">Пароль должен содержать латинские буквы и цифры, а длина пароля — <strong class="content_700">не менее 6 знаков</strong>.
      </div>
    </div>
  </div>
</template>

<script>
import vmMixin from "@/mixins/vmMixin";
import Autocomplete from "@/components/Autocomplete.vue";
import {mapGetters} from "vuex";

export default {
  name: "RegistrationFormCompany",
  components: {Autocomplete},
  mixins: [vmMixin],
  computed: {
    ...mapGetters(['mspForsageOrderRequest'])
  }
}
</script>

<style scoped>

</style>