<template>
  <div v-if="false" class="modal-form__footer modal-register-footer register-sms">
    <div class="m-0 w-100 modal-footer-authorization modal-footer-animation modal-register-footer_sms">
      <div class="row align-items-center">
        <div class="col">
          <button class="btn btn-outline-primary">Запросить код повторно</button>
        </div>
        <div class="col-auto">
          <div class="d-flex align-items-center">
            <span>Код из СМС</span>
            <input type="text" size="1" class="form-control" id="num-1"/>
            <input type="text" size="1" class="form-control" id="num-2"/>
            <input type="text" size="1" class="form-control" id="num-3"/>
            <input type="text" size="1" class="form-control" id="num-4"/>
          </div>
        </div>
        <div class="col-auto">
          <button type="submit" rel="letter" class="nav-form link-target"><span>подтвердить</span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistrationFormSms"
}
</script>

<style scoped>

</style>