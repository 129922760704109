import { render, staticRenderFns } from "./RegistrationFormButton.vue?vue&type=template&id=7018b92b&scoped=true&"
import script from "./RegistrationFormButton.vue?vue&type=script&lang=js&"
export * from "./RegistrationFormButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7018b92b",
  null
  
)

export default component.exports