<template>
  <div v-if="false" class="checkbox-wrapper modal-register-conditions-sms">
    <div class="checkbox">
      <input type="checkbox" id="register-sms"/>
      <label for="remember">Я согласен(на) получать важные уведомления по SMS</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistrationFormSmsCheckbox"
}
</script>

<style scoped>

</style>