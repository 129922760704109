// @ts-nocheck
export default {
    data() {
        return {
            type: 'user'
        }
    },
    mounted() {
        if(this.$route.query.type) {
            this.type = this.$route.query.type
        }
    }
}