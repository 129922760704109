<template>
  <div class="modal-form__footer modal-register-footer register-default">
    <div class="m-0 mb-5 w-100 modal-footer-authorization modal-footer-animation">
      <div class="row align-items-center">
        <div class="col">
          <div class="content_18" v-if="!mspForsageOrderRequest">Уже есть аккаунт? <router-link class="nav-form content_red content_underline modal-register" to="/sign-in">Войти</router-link></div>
        </div>
        <div class="col-auto">
          <button type="submit" class="link-target modal-register__submit"><span>зарегистрироваться</span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "RegistrationFormButton",
  computed: {
    ...mapGetters(['mspForsageOrderRequest'])
  }
}
</script>

<style scoped>

</style>