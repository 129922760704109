<template>
  <div class="checkbox-wrapper modal-register-conditions">
    <div class="checkbox">
      <input name="согласие" type="checkbox" v-model="model" id="register-conditions"/>
      <label for="register-conditions">Я согласен(на) с условиями <a target="_blank" href="https://exportedu.ru/agreement">пользовательского соглашения</a> и
        <a target="_blank" href="https://exporteduru.servicecdn.ru/media/default/0003/12/afe4c45ca00400534ab0ec8b7cc4189169882841.pdf">политикой обработки персональных данных</a></label>
    </div>
  </div>
</template>

<script>
import vmMixin from "@/mixins/vmMixin";

export default {
  name: "RegistrationFormCondition",
  mixins: [vmMixin]

}
</script>

<style scoped>

</style>